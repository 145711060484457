import { router } from "../router";

export function getQuery2QueryString(queryKeys: string[]) {
    const routerQueryParams = router.currentRouteData.getValue.query;

    let queryString = "";

    if (queryKeys && Array.isArray(queryKeys) && queryKeys.length > 0) {
        queryKeys.forEach((queryKey) => {
            let queryValue = routerQueryParams[queryKey];

            if (queryValue) {
                queryString += queryString === "" ? "?" : "&";
                queryString += `${queryKey}=${queryValue}`;
            }
        });
    } else {
        return "";
    }

    return queryString;
}

/**
 * Creates a string containing the query values formatted as params
 * @param {string[]} queryKeys - e.g. ["id", "hash_id"]
 * @returns {string} something like "/queryValue1/queryValue2/..."
 */
export function getQuery2ParamString(queryKeys: string[]) {
    const routerQueryParams = router.currentRouteData.getValue.query;

    let queryString = "";

    if (queryKeys && Array.isArray(queryKeys) && queryKeys.length > 0) {
        queryKeys.forEach((queryKey) => {
            let queryValue = routerQueryParams[queryKey];

            if (queryValue) {
                queryString += `/${queryValue}`;
            }
        });
    } else {
        return "";
    }

    return queryString;
}

export function getParam2QueryString(queryKeys: string[]) {
    const routerParams = router.currentRoute.params;

    let queryString = "";

    if (queryKeys && Array.isArray(queryKeys) && queryKeys.length > 0) {
        queryKeys.forEach((queryKey) => {
            let queryValue = routerParams[queryKey];

            if (queryValue) {
                queryString += queryString === "" ? "?" : "&";
                queryString += `${queryKey}=${queryValue}`;
            }
        });
    } else {
        return "";
    }

    return queryString;
}

export function getUrlParams(queryKeys: string[]) {
    const routerParams = router.currentRoute.params;

    const params: Record<string, string> = {};

    if (queryKeys && Array.isArray(queryKeys) && queryKeys.length > 0) {
        queryKeys.forEach((queryKey) => {
            let queryValue = routerParams[queryKey];

            if (queryValue) {
                params[queryKey] = queryValue;
            }
        });
    }

    return params;
}

export function getSpecificQueryValue(key: string) {
    if (!key) {
        console.warn("no key specified!");
        return "";
    }

    const routerQueryParams = router.currentRouteData.getValue.query;

    if (routerQueryParams[key]) {
        return routerQueryParams[key];
    }

    return "";
}

export function getSearchParams(): Array<{ key: string; value: string }> {
    if (URLSearchParams !== undefined) {
        const params = new URLSearchParams(window.location.search);
        const paramsCleaned = [...params.entries()].map(([key, value]) => ({
            key: key,
            value: value,
        }));
        return paramsCleaned;
    } else {
        return [];
    }
}
