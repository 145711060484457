<script lang="ts">
    import { loc, locSettings } from "../store/loc.store";
    import { router } from "../router";

    import Layout from "../components/Layout.svelte";
    import Modal from "../components/modals/Modal.svelte";
    import UserControlComponent from "../components/UserControlComponent.svelte";
    import ButtonAtom from "../atoms/ButtonAtom.svelte";
    import { isReseller, logoAlt, logoLink, logoPath } from "../consts/reseller";
</script>

<div class="error-nowebgl">
    <Layout size="full">
        <svelte:fragment slot="header_left">
            <img src={logoPath} alt={logoAlt} class="logo" />
            {#if isReseller}
                {$loc.map("locKey_powered_by_hts")}
            {/if}
        </svelte:fragment>

        <svelte:fragment slot="header_right">
            <UserControlComponent hiddenMenuItems={["search", "account"]} />
        </svelte:fragment>

        <Modal closeable={false} applyErrorStyle={true}>
            <svelte:fragment slot="header">
                <h2>{$loc.map("locKey_error_nowebgl_title")}</h2>
            </svelte:fragment>

            {@html $loc.map("locKey_error_nowebgl_text")}

            <svelte:fragment slot="footer" let:close>
                <a class="button" href="https://bestvpn.org/outdatedbrowser">{$loc.map("locKey_browser_selection")}</a>
            </svelte:fragment>
        </Modal>
    </Layout>
</div>

<style lang="scss" type="text/scss">
    @use "../scss/vars" as *;
    @use "../scss/rem" as *;

    .error-nowebgl {
        :global {
            .layout {
                background-color: var(--color-primary-4);

                .header {
                    z-index: 510;
                }
            }
        }
    }

    :global {
        a.button {
            position: relative;
            margin: 0;
            white-space: nowrap;
            cursor: pointer;
            text-decoration: none;
            padding: rem(10) rem(15);
            border-radius: 0;

            background-color: var(--signal);
            border: 1px solid var(--signal-dark);
            color: var(--color-secondary-100);
            font-weight: $font-weight-bold;
            font-size: rem(14);
            text-transform: uppercase;

            &:hover {
                background-color: var(--signal-hover);
                box-shadow: 0 0 0 1px var(--signal-dark);
            }
        }
    }
</style>
