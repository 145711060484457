<script>
    export let title;
    export let x;
    export let y;
</script>

<div
    class="tooltip"
    style="
		top: {y + 8}px;
		left: {x + 8}px;"
>
    <div class="tooltip-title">{title}</div>
</div>

<style lang="scss" type="text/scss">
    @import "../scss/vars";
    @import "../scss/rem";

    .tooltip {
        position: absolute;
        box-shadow: 0px 0px 10px 0px var(--color-secondary-24);
        background-color: var(--color-tootlip-bg);
        padding: rem(6);
        display: flex;
        gap: rem(10);
        align-items: center;
        max-width: rem(300);
        border-radius: rem(12);
        z-index: 300;

        .tooltip-title {
            color: var(--color-tooltip-text);
            font-size: rem(10);
        }
    }
</style>
