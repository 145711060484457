<script lang="ts" context="module">
    import { get, writable } from "svelte/store";
    import type { ModalActionType } from "./Modal.svelte";
    import { getNextId, modalStack } from "./Modal.svelte";
    import SaveConfigModal from "./type/SaveConfigModal.svelte";
    import IDInputModal from "./type/IDInputModal.svelte";
    import CreatePlanningModal from "./type/CreatePlanningModal.svelte";
    import LogInModal from "./type/LogInModal.svelte";
    import SavePlanningModal, { type SavePlanningModalProps } from "./type/SavePlanningModal.svelte";
    import type { SubmitModalProps } from "./type/SubmitModal.svelte";
    import SubmitModal from "./type/SubmitModal.svelte";

    enum EModalTypes {
        LOGIN_MODAL = "LogInModal",
        ID_INPUT_MODAL = "IdInputModal",
        EXTERNAL_CONFIG_SAVE_MODAL = "SaveConfigModal",
        INTERNAL_CONFIG_SAVE_MODAL = "CreatePlanningModal",
        SAVE_PLANNING_MODAL = "SavePlanningModal",
        SUBMIT_MODAL = "SubmitModal",
    }

    const modalType2Component: Record<EModalTypes, any> = {
        [EModalTypes.LOGIN_MODAL]: LogInModal,
        [EModalTypes.ID_INPUT_MODAL]: IDInputModal,
        [EModalTypes.EXTERNAL_CONFIG_SAVE_MODAL]: SaveConfigModal,
        [EModalTypes.INTERNAL_CONFIG_SAVE_MODAL]: CreatePlanningModal,
        [EModalTypes.SAVE_PLANNING_MODAL]: SavePlanningModal,
        [EModalTypes.SUBMIT_MODAL]: SubmitModal,
    };

    const dynamicModalsStore = writable<
        Array<{
            id: number;
            type: EModalTypes;
            onModalAction: Array<[string, (payload: any) => void]>;
            props: Record<string, any>;
        }>
    >([]);

    const remove = (removeIds: number[]) => {
        dynamicModalsStore.update((current) => {
            return current.filter(({ id }) => removeIds.includes(id) === false);
        });
    };

    function showModal<T>(modal: EModalTypes, props: Record<string, any> = {}) {
        let modalId = getNextId();
        dynamicModalsStore.update((current) => [...current, { id: modalId, type: modal, onModalAction: [], props }]);
        return {
            close: () => remove([modalId]),
            onModalAction: <K extends keyof T>(action: K, modalAction: (payload: T[K]) => void) => {
                const dynamicModals = get(dynamicModalsStore);
                const modal = dynamicModals.find(({ id }) => id === modalId);

                if (modal !== undefined) {
                    modal.onModalAction.push([action as string, modalAction]);
                }
            },
        };
    }

    export const showModal_LogInModal = () => showModal(EModalTypes.LOGIN_MODAL);
    export const showModal_IDInputModal = () => showModal<{ open: { id: string } }>(EModalTypes.ID_INPUT_MODAL);
    export const showModal_SaveConfigModal = () =>
        showModal<{ save: { mailAddress: string } }>(EModalTypes.EXTERNAL_CONFIG_SAVE_MODAL);
    export const showModal_CreatePlanningModal = () =>
        showModal<{ save: { name: string } }>(EModalTypes.INTERNAL_CONFIG_SAVE_MODAL);
    export const showModal_SavePlanningModal = (modalProps: SavePlanningModalProps) =>
        showModal<{ save: { name: string; saveOption: "overwrite" | "new" } }>(EModalTypes.SAVE_PLANNING_MODAL, {
            modalProps,
        });
    export const showModal_SubmitModal = (props: SubmitModalProps) =>
        showModal<{ submit: void; close: void }>(EModalTypes.SUBMIT_MODAL, props);

    modalStack.subscribe((stack) => {
        const dynamicModals = get(dynamicModalsStore);
        const modalIdsNoLongerExists = dynamicModals
            .filter(({ id }) => stack.includes(id) === false)
            .map(({ id }) => id);

        remove(modalIdsNoLongerExists);
    });

    function handleDynamicModalAction(event: { detail: ModalActionType }) {
        const dynamicModals = get(dynamicModalsStore);
        const modalListener = dynamicModals.find(
            ({ id, onModalAction }) => id === event.detail.modalId && onModalAction !== undefined
        );
        const allMatchedListeners =
            modalListener?.onModalAction.filter(([action]) => action === event.detail.action) ?? [];
        allMatchedListeners.forEach(([_, listener]) => listener(event.detail.payload));
    }
</script>

{#each $dynamicModalsStore as dynamicModal (dynamicModal.id)}
    <svelte:component
        this={modalType2Component[dynamicModal.type]}
        on:modalAction={handleDynamicModalAction}
        {...dynamicModal.props}
    />
{/each}

<div id="modal-portal" />
