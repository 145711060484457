<script lang="ts">
    import { mailAddress_Schema } from "shared/lib/interfaces/generic";
    import ButtonAtom from "../../../atoms/ButtonAtom.svelte";
    import InputAtom from "../../../atoms/InputAtom.svelte";
    import { loc } from "../../../store/loc.store";
    import ErrorMessageComponent from "../../ErrorMessageComponent.svelte";
    import Modal from "../Modal.svelte";

    let mailAddress = "";
    let error = false;
    let errorMessage = "";
</script>

<Modal backdrop={true} let:dispatchModalAction on:modalAction on:close applyErrorStyle={error}>
    <svelte:fragment slot="header">
        <h2>{$loc.map("locKey_modal_save_config")}</h2>
    </svelte:fragment>

    <p>{$loc.map("locKey_modal_save_config_external_instructions")}</p>

    <ErrorMessageComponent {errorMessage}>
        <form on:submit|preventDefault={() => dispatchModalAction({ action: "save", payload: { mailAddress } })}>
            <InputAtom
                id="email-input"
                {error}
                placeholder={$loc.map("locKey_modal_email_input")}
                bind:value={mailAddress}
                on:input={() => {
                    error = false;
                    errorMessage = undefined
                }}
            >
                <i slot="prefix" class="zmdi zmdi-email" />
            </InputAtom>
        </form>
    </ErrorMessageComponent>

    <p class="legal-notice">{@html $loc.map("locKey_hts_privacy_statement")}</p>

    <svelte:fragment slot="footer" let:dispatchModalAction let:close>
        <ButtonAtom
            color="secondary"
            size="small"
            on:click={() => {
                close();
            }}
        >
            {$loc.map("locKey_cancel")}
        </ButtonAtom>

        <ButtonAtom
            color="primary"
            size="small"
            on:click={() => {
                const joiErrors = mailAddress_Schema
                    .message($loc.map("locKey_request_field_should_be_valid_email"))
                    .validate(mailAddress, { abortEarly: false });

                if (joiErrors.error) {
                    error = true;
                    errorMessage = $loc.map("locKey_email_error")
                }
                else{
                    dispatchModalAction({ action: "save", payload: { mailAddress } })}}
                }
                
        >
            {$loc.map("locKey_send")}
            <i class="zmdi zmdi-long-arrow-right" />
        </ButtonAtom>
    </svelte:fragment>
</Modal>

<style lang="scss">
    @use "../../../scss/rem" as *;

    p {
        line-height: 1.5;
    }

    .legal-notice {
        font-size: rem(10);
        color: var(--color-legal-notice-text);
    }
</style>
