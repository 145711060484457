import { build } from "@redplant3d/redtyped";
import { assertUnreachable } from "@redplant3d/redtyped/lib";
import { Country } from "shared/lib/interfaces/country";
import type { Message } from "shared/lib/interfaces/message.types";
import type {
    BomEntry,
    SessionContext,
    StateMutateResult,
    UISessionMutate,
    UnknownState,
} from "shared/lib/interfaces/session";
import { CameraView } from "shared/lib/interfaces/session";
import type { Readable, Writable } from "svelte/store";
import { derived, get as storeGet, writable } from "svelte/store";
import type { Language } from "../../../shared/lib/localization/loc.types";
import { IconEnvironMentIndustry, IconEnvironMentMountains, IconEnvironMentStudio } from "../consts/icons";
import { get as httpGet, post as httpPost } from "../services/fetch.api";
import { addTrackingEvent } from "../services/tracking.service";
import { locSettings } from "./loc.store";
import { userStore } from "./user.store";

const emptyStateResult: StateMutateResult = {
    bom: [],
    session: {
        options: {},
        state: {},
        validation: {},
        session_attributes: {
            selectables: [],
            cameraViews: [],
            measurements: [],
        },
    },
    session_context: {
        selection: {
            value: [],
            scope: "user",
        },
        language: {
            value: (window.localStorage.getItem("language") as Language) ?? "de",
            scope: "user",
        },
        cameraView: {
            value: CameraView.OuterView,
            scope: "user",
        },
        sales_country: {
            value: Country.DE,
            scope: "shared",
        },
        measurements: {
            value: false,
            scope: "user",
        },
        environment: {
            value: "blank",
            scope: "user",
        },
        reseller: {
            value: window.reseller ?? "hts",
            scope: "user",
        },
    },
    prices: {},
    ui: {},
    messages: [],
};

const stateMutateResult = writable<StateMutateResult>(emptyStateResult);

export const bom = derived<Readable<StateMutateResult>, StateMutateResult["bom"]>(
    stateMutateResult,
    ($stateMutateResult) => $stateMutateResult.bom
);
export const ui = derived<Readable<StateMutateResult>, StateMutateResult["ui"]>(
    stateMutateResult,
    ($stateMutateResult) => $stateMutateResult.ui
);
export const session = derived<Readable<StateMutateResult>, StateMutateResult["session"]>(
    stateMutateResult,
    ($stateMutateResult) => $stateMutateResult.session
);
export const session_context = derived<Readable<StateMutateResult>, StateMutateResult["session_context"]>(
    stateMutateResult,
    ($stateMutateResult) => $stateMutateResult.session_context
);
export const prices = derived<Readable<StateMutateResult>, StateMutateResult["prices"]>(
    stateMutateResult,
    ($stateMutateResult) => $stateMutateResult.prices
);

const desiredState = writable<StateMutateResult["session"]["state"]>(emptyStateResult.session.state);
const desiredLanguage = writable<StateMutateResult["session_context"]["language"]["value"]>(
    emptyStateResult.session_context.language.value
);
const desiredCameraView = writable<StateMutateResult["session_context"]["cameraView"]["value"]>(
    emptyStateResult.session_context.cameraView.value
);
const desiredMeasurements = writable<StateMutateResult["session_context"]["measurements"]["value"]>(
    emptyStateResult.session_context.measurements.value
);

/**
 * ----------------------
 * ###### MESSAGES ######
 * ###### MESSAGES ######
 * ###### MESSAGES ######
 * ----------------------
 */

let messageIDCounter = 0;

export const messages = derived<Readable<StateMutateResult>, Message[]>(stateMutateResult, ($stateMutateResult) => {
    // const messages = $stateMutateResult.bom.errors.map<Message>((value) => ({
    //     id: (messageIDCounter++).toString(),
    //     type: "bom_error",
    //     timestamp: Date.now(),
    //     value,
    // }));

    // Add other messages
    const messages = $stateMutateResult.messages;

    if (import.meta.env.DEV === true) {
        messages.forEach((message) => console.warn(message.value));
    }

    return messages;
});

const disabledMessageIDs = writable<string[]>([]);
export const disableMessage = (id: string) => {
    disabledMessageIDs.update((ids) => {
        ids.push(id);
        const $messages = storeGet(messages);
        return ids.filter((id) => {
            return $messages.some((message) => message.id === id);
        });
    });
    // console.log("disabledMessageIDs", storeGet(disabledMessageIDs));
};

export const messagesFiltered = derived<[Readable<Message[]>, Writable<string[]>], Message[]>(
    [messages, disabledMessageIDs],
    ([$messages, $disabledMessageIDs]) => {
        return $messages.filter(({ id }) => $disabledMessageIDs.includes(id) === false);
    }
);

export type DiffEntry = {
    MATNR: string;
    type: "new" | "changed" | "unchanged" | "removed";
    quantity_from: number;
    quantity_to: number;
};

export const diffBomEntries = (fromEntries: BomEntry[], toEntries: BomEntry[]): DiffEntry[] => {
    const fromMATNRs = fromEntries.map(({ MATNR }) => MATNR);
    const toMATNRs = toEntries.map(({ MATNR }) => MATNR);

    // detect new entries
    const newEntries = toEntries
        .filter(({ MATNR }) => fromMATNRs.includes(MATNR) === false)
        .map<DiffEntry>(({ MATNR, quantity }) => ({
            MATNR,
            type: "new",
            quantity_from: 0,
            quantity_to: quantity,
        }));

    // detect quantity changes
    const changedEntries = toEntries.reduce<DiffEntry[]>((acc, toEntry) => {
        const fromEntry = fromEntries.find((fromEntry) => fromEntry.MATNR === toEntry.MATNR);
        if (fromEntry !== undefined && fromEntry.quantity !== toEntry.quantity) {
            acc.push({
                MATNR: toEntry.MATNR,
                type: "changed",
                quantity_to: toEntry.quantity,
                quantity_from: fromEntry.quantity,
            });
        }
        return acc;
    }, []);

    // detect unchanged
    const unchangedEntries = toEntries
        .filter((toEntry) => {
            const inNew = newEntries.some(({ MATNR }) => MATNR === toEntry.MATNR);
            const inChanged = changedEntries.some(({ MATNR }) => MATNR === toEntry.MATNR);
            return inNew === false && inChanged === false;
        })
        .map<DiffEntry>(({ MATNR, quantity }) => ({
            MATNR,
            type: "unchanged",
            quantity_from: quantity,
            quantity_to: quantity,
        }));

    // detect removed entries
    const removedEntries = fromEntries
        .filter(({ MATNR }) => toMATNRs.includes(MATNR) === false)
        .map<DiffEntry>(({ MATNR, quantity }) => ({
            MATNR,
            type: "removed",
            quantity_from: quantity,
            quantity_to: 0,
        }));

    return [...newEntries, ...unchangedEntries, ...changedEntries, ...removedEntries].sort((a, b) =>
        a.MATNR.localeCompare(b.MATNR)
    );
};

export const bomDiff = (() => {
    let fromEntries: BomEntry[] = [];
    return derived(bom, ($bom) => {
        const toEntries = $bom.filter((entry) => entry.type !== "tent");
        const diffMATNRS = diffBomEntries(fromEntries, toEntries);
        fromEntries = toEntries;
        // console.log(diffMATNRS);
        return diffMATNRS;
    });
})();

export const loading = writable({
    page: false, //toggle router beforeEach and afterEach
    productsReady: false, //OnProductsReady
    mutation: false, //mutation is awaiting
});

export const createDebouncedHandler = (startCb: () => void, delay = 100, minimumVisibleTime = 200) => {
    let startedTime = -1;
    const startId = setTimeout(() => {
        startedTime = performance.now();
        startCb();
    }, delay);

    return (clearCb: () => void) => {
        if (startedTime === -1) {
            clearTimeout(startId);
            return;
        }

        const deltaTime = performance.now() - startedTime;
        if (deltaTime >= delay + minimumVisibleTime) {
            clearCb();
        } else {
            setTimeout(clearCb, delay + minimumVisibleTime - deltaTime);
        }
    };
};

type StateHistoryEntry = StateMutateResult["session"]["state"];
type StateHistory = Array<{
    timestamp: number;
    state: StateHistoryEntry;
}>;

const stateHistory = writable<StateHistory>([]);

const historyTryAdd = (state: StateHistoryEntry, mode: "start" | "mutate" | "undo") => {
    stateHistory.update(($stateHistory) => {
        const tidiedHistory: StateHistory = [];

        if (mode !== "start") {
            tidiedHistory.push(...$stateHistory.slice(-10));
        }

        if (mode !== "undo") {
            const tailEntry = tidiedHistory[tidiedHistory.length - 1];
            const shouldAdd = tailEntry === undefined || tailEntry.state.__config_mutation !== state.__config_mutation;

            if (shouldAdd === true) {
                tidiedHistory.push({
                    timestamp: Date.now(),
                    state,
                });
            }
        }

        if (mode === "undo") {
            tidiedHistory.pop();
        }

        tidiedHistory.sort(({ timestamp: a }, { timestamp: b }) => a - b);

        // console.table(
        //     tidiedHistory.map((entry) => ({
        //         timestamp: entry.timestamp,
        //         id: entry.state.id,
        //         __config_mutation: entry.state.__config_mutation,
        //     }))
        // );

        return tidiedHistory;
    });
};

export const undoAvailable = derived(stateHistory, ($stateHistory) => $stateHistory.length > 1);

export const undoState = () => {
    const $stateHistory = storeGet(stateHistory);
    if ($stateHistory.length <= 1) {
        console.warn("Undo not available");
        return;
    }

    const targetEntry = $stateHistory[$stateHistory.length - 2];
    mutateSession(
        {
            state: targetEntry.state,
        },
        "undo"
    );
};

let runningUpdate: Promise<StateMutateResult> | null = null;

function handleUpdateTry(updatePromise: Promise<StateMutateResult>, mode: "start" | "mutate" | "undo") {
    runningUpdate = updatePromise;

    const handler = createDebouncedHandler(() => {
        loading.update(($loading) => {
            $loading.mutation = true;
            return $loading;
        });
    });

    return updatePromise
        .then((result) => {
            // if is still the current update run
            if (runningUpdate === updatePromise || mode === "start") {
                // clear pending
                pendingSelection.set([]);

                // try add to history
                historyTryAdd(result.session.state, mode);

                // track mutations for user conversions
                addTrackingEvent("config mutation", `${result.session.state.__config_mutation}`);

                // hard overwrite
                stateMutateResult.set(result);

                // clear promise
                runningUpdate = null;
            } else {
                console.warn("update race");
            }
        })
        .catch((error) => {
            if (runningUpdate === updatePromise) {
                console.error("", error);
                runningUpdate = null;
            } else {
                console.warn("update race catch");
            }
        })
        .finally(() => {
            handler(() => {
                loading.update(($loading) => {
                    $loading.mutation = false;
                    return $loading;
                });
            });
        });
}

export function startSession(
    state_id: string,
    sales_country: string | undefined,
    hash_id: string | undefined,
    session_id: string | undefined
) {
    console.log("Start startSession", state_id, sales_country, hash_id);

    const searchParams = new URLSearchParams();
    if (session_id !== undefined) {
        searchParams.append("planning", session_id);
    }
    if (sales_country !== undefined) {
        searchParams.append("sales_country", sales_country);
    }

    const url = [
        `/api/v1/product/start/${state_id}/`,
        hash_id || "",
        searchParams.size > 0 ? "?" : "",
        searchParams.toString(),
    ].join("");

    void handleUpdateTry(httpGet<StateMutateResult>(url), "start");
}

export function mutateSession(
    input: {
        session_value?: unknown;
        session_mutate?: UISessionMutate;
        language?: SessionContext["language"]["value"];
        cameraView?: SessionContext["cameraView"]["value"];
        state?: UnknownState;
        measurements?: SessionContext["measurements"]["value"];
        environment?: SessionContext["environment"]["value"];
    },
    mode: "mutate" | "undo" = "mutate",
    forcePreloader: boolean = false
) {
    //force loading state before mutate
    if (mode === "mutate" && forcePreloader) {
        loading.update(($loading) => {
            $loading.mutation = true;
            return $loading;
        });
    }

    const state = input.state ?? storeGet(session).state;
    const context = storeGet(session_context);
    const pending = storeGet(pendingSelection);

    console.log("Start mutateSession", state.id);

    // pending false -> remove from context
    const toRemove = pending.filter(([_, selection]) => selection === false).map(([selectionId, _]) => selectionId);
    context.selection.value = context.selection.value.filter((selectionId) => toRemove.includes(selectionId) === false);

    // pending true -> add to context
    const toAdd = pending.filter(([_, selection]) => selection === true).map(([selectionId, _]) => selectionId);
    context.selection.value.push(...toAdd);

    // clear timer from pending selection
    clearTimeout(pendingSelectionTimerId);

    // optional apply language
    context.language.value = input.language ?? context.language.value;

    // optional apply cameraView
    context.cameraView.value = input.cameraView ?? context.cameraView.value;

    context.measurements.value = input.measurements ?? context.measurements.value;

    context.environment.value = input.environment ?? context.environment.value;

    const data = {
        session_context: context,
        session_mutate: input.session_mutate,
        session_value: input.session_value,
        state,
    };

    void handleUpdateTry(httpPost<StateMutateResult>(`/api/v1/product/mutate`, JSON.stringify(data)), mode).finally(
        () => {
            if (mode === "mutate" && forcePreloader) {
                loading.update(($loading) => {
                    $loading.mutation = false;
                    return $loading;
                });
            }
        }
    );
}

/**
 * ------------------------------------------
 * ###### SESSION_CONTEXT - SELECTION ######
 * ###### SESSION_CONTEXT - SELECTION ######
 * ###### SESSION_CONTEXT - SELECTION ######
 * -----------------------------------------
 */

export const pendingSelection = writable<Array<[selectionId: string, selected: boolean]>>([]);
let pendingSelectionTimerId = -1;

export const selection = derived<
    [
        // Current state
        Readable<StateMutateResult>,
        // Pending selections
        Readable<Array<[selectionId: string, selected: boolean]>>
    ],
    Array<[string, boolean]>
>([stateMutateResult, pendingSelection], ([$stateMutateResult, $pendingSelection]) => {
    const newSelection: Array<[string, boolean]> = [];
    $stateMutateResult.session.session_attributes.selectables.forEach((pathId) => {
        const selectionInContext = $stateMutateResult.session_context.selection.value.includes(pathId);

        //  maybe we have a pending selection
        const pending = $pendingSelection.find(([pendingSelectionId, _]) => pendingSelectionId === pathId);

        // overwrite the current selection if has pending
        if (pending) {
            newSelection.push(pending);
        } else {
            newSelection.push([pathId, selectionInContext]);
        }
    });
    return newSelection;
});

export const toggleSelection: (data: [string, boolean]) => void = ([selectionId, selected]) => {
    // console.log("toggleSelection", selectionId, selected);

    // cleanup -> remove the id from the pending
    const update = storeGet(pendingSelection).filter(([pendingSelectionId, _]) => pendingSelectionId !== selectionId);

    // find out if the id is selected in the real store
    const selectionInStore = storeGet(session_context).selection.value;
    const isSelectedInStore = selectionInStore.includes(selectionId);

    // push the pendingselection only if the state differs
    if (isSelectedInStore !== selected) {
        update.push([selectionId, selected]);
    }

    pendingSelection.set(update);

    clearTimeout(pendingSelectionTimerId);

    if (update.length > 0) {
        pendingSelectionTimerId = setTimeout(() => {
            mutateSession({});
        }, 1000) as unknown as number;
    }
};

export const toggleSelectionAll = (selected: boolean) => {
    clearTimeout(pendingSelectionTimerId);
    // console.log("toggleSelectionAll", selected);

    const selectionInStore = storeGet(session_context).selection.value;
    const allSelectables = storeGet(session).session_attributes.selectables;

    if (selected === true) {
        // find all not selected id and add them to the pending
        const unselectedIds = allSelectables.filter((selectable) => selectionInStore.includes(selectable) === false);
        pendingSelection.set(unselectedIds.map((id) => [id, true]));
        mutateSession({});
    } else {
        // find all selected ids and
        const selectedIds = allSelectables.filter((selectable) => selectionInStore.includes(selectable) === true);
        pendingSelection.set(selectedIds.map((id) => [id, false]));
        mutateSession({});
    }
};

/**
 * --------------------------------------
 * ###### SESSION_CONTEXT - CAMERA ######
 * ###### SESSION_CONTEXT - CAMERA ######
 * ###### SESSION_CONTEXT - CAMERA ######
 * --------------------------------------
 */

export const currentCameraViewMode = derived(
    stateMutateResult,
    ($stateMutateResult) => $stateMutateResult.session_context.cameraView.value
);

export const availableCameraViewModes = derived(
    stateMutateResult,
    ($stateMutateResult) => $stateMutateResult.session.session_attributes.cameraViews
);

export const toggleCameraView = (debug?: boolean) => {
    const context = storeGet(session_context);
    if (debug && build.Options.development) {
        mutateSession({ cameraView: CameraView.DebugView });
    } else if (context.cameraView.value === CameraView.InnerView) {
        mutateSession({ cameraView: CameraView.OuterView });
        mutateSession({ measurements: false });
    } else {
        addTrackingEvent("open interior view", "click interior view");
        mutateSession({ cameraView: CameraView.InnerView });
        mutateSession({ measurements: false });
    }
};

/**
 * --------------------------------------
 * ###### SESSION_CONTEXT - MEASUREMENTS ######
 * ###### SESSION_CONTEXT - MEASUREMENTS ######
 * ###### SESSION_CONTEXT - MEASUREMENTS ######
 * --------------------------------------
 */

export const currentMeasurementVisibility = derived(
    stateMutateResult,
    ($stateMutateResult) => $stateMutateResult.session_context.measurements.value
);

export const availableMeasurementVisibility = derived(
    stateMutateResult,
    ($stateMutateResult) => $stateMutateResult.session.session_attributes.measurements
);

export const toggleMeasurements = () => {
    const context = storeGet(session_context);
    if (context.measurements.value === false) {
        mutateSession({ measurements: true });
    } else {
        mutateSession({ measurements: false });
    }
};

export const toggleEnvironments = () => {
    const context = storeGet(session_context);

    switch (context.environment.value) {
        case "blank":
            mutateSession({ environment: "mountain" }, "mutate", true);
            break;
        case "mountain":
            mutateSession({ environment: "industrial" }, "mutate", true);
            break;
        case "industrial":
            mutateSession({ environment: "blank" }, "mutate", true);
            break;
        default:
            assertUnreachable(context.environment.value);
    }
};

export const environmentIcon = derived(session_context, ($session_context) => {
    switch ($session_context.environment.value) {
        case "blank":
            return IconEnvironMentStudio;

        case "mountain":
            return IconEnvironMentMountains;
        case "industrial":
            return IconEnvironMentIndustry;
        default:
            assertUnreachable($session_context.environment.value);
    }
});

/**
 * ----------------------------------------
 * ###### SESSION_CONTEXT - LANGUAGE ######
 * ###### SESSION_CONTEXT - LANGUAGE ######
 * ###### SESSION_CONTEXT - LANGUAGE ######
 * ----------------------------------------
 */

locSettings.subscribe(($locSettings) => {
    if ($locSettings.language !== storeGet(session_context).language.value) {
        mutateSession({
            language: $locSettings.language,
        });
    }
});

/**
 * -------------------------------
 * ###### LOGIN - REVALIDATE######
 * ###### LOGIN - REVALIDATE######
 * ###### LOGIN - REVALIDATE######
 * -------------------------------
 */

userStore.subscribe(($userStore) => {
    const hasPriceResults = Object.keys(storeGet(prices)).length > 0;
    if (hasPriceResults === true && $userStore.isLoggedIn === true) {
        mutateSession({});
    }
});
