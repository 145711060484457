<script lang="ts" context="module">
    export type LayoutSizeType = "form" | "grid" | "full" | "default" | "orderOverview" | "orderOverviewWide";
    export type LayoutBgColorType = "modal" | "page";
</script>

<script lang="ts">
    import type { User } from "../store/user.store";

    import { breakpoint } from "../MediaQuery.svelte";
    import Banner from "../components/Banner.svelte";

    export let centered: boolean = false;
    export let headerFloating: boolean = true;
    export let headerLeftTransparent: boolean = false;
    export let headerLeftFlowColumn: boolean = true;
    export let headerRightFlowColumn: boolean = true;
    export let footerFloating: boolean = true;
    export let footerPadding: boolean = true;
    export let bgColor: LayoutBgColorType = "page";
    export let size: LayoutSizeType = "default";
    export let department: User["department"] | undefined = undefined;
    export let sapSystem: User["source"] | undefined = undefined;
    export let configurationInfo: User["configurationInfo"] = undefined;

    import { createEventDispatcher } from "svelte";

    let headerHeight = 0;
    let isScrolled = false;
    let isScrolledToBottom = false;

    const dispatch = createEventDispatcher<{
        scrolled: boolean;
        scrolledToBottom: boolean;
    }>();

    $: dispatch("scrolled", isScrolled);
    $: dispatch("scrolledToBottom", isScrolledToBottom);

    $: onlyOneFooterAvailable = $$slots.footer_left === undefined || $$slots.footer_right === undefined;
</script>

<div
    class="layout bgColor-{bgColor} size-{size} breakpoint-{$breakpoint}"
    on:scroll={(event) => {
        const { scrollTop, offsetHeight, scrollHeight } = event.currentTarget;
        isScrolled = scrollTop > 10;
        isScrolledToBottom = scrollTop + offsetHeight + 200 >= scrollHeight;
    }}
>
    {#if $$slots.header_left || $$slots.header_right || department !== undefined}
        <div class="headerwrapper">
            {#if department !== undefined}
                <div class="department">
                    <Banner {department} {sapSystem} {configurationInfo} />
                </div>
            {/if}
            {#if $$slots.header_left || $$slots.header_right}
                <div
                    class="header"
                    class:onlyRight={!!$$slots.header_left === false && !!$$slots.header_right === true}
                    bind:clientHeight={headerHeight}
                >
                    {#if $$slots.header_left}
                        <div
                            class="left"
                            class:floating={headerFloating}
                            class:transparent={headerLeftTransparent}
                            class:flex-column={headerLeftFlowColumn}
                            class:shadow={isScrolled}
                        >
                            <slot name="header_left" />
                        </div>
                    {/if}

                    {#if $$slots.header_right}
                        <div
                            class="right"
                            class:floating={headerFloating}
                            class:shadow={isScrolled}
                            class:flex-column={headerRightFlowColumn}
                        >
                            <slot name="header_right" />
                        </div>
                    {/if}
                </div>
            {/if}
        </div>
    {/if}
    <div class="content" class:centered>
        <slot {isScrolled} {isScrolledToBottom} {headerHeight} />
    </div>

    {#if $$slots.footer_left || $$slots.footer_right}
        <div class="footer">
            <div class="footer-content">
                {#if $$slots.footer_left}
                    <div class="left" class:with-padding={footerPadding} class:floating={footerFloating}>
                        <slot name="footer_left" />
                    </div>
                {/if}

                {#if $$slots.footer_right}
                    <div class="right" class:with-padding={footerPadding} class:floating={footerFloating}>
                        <slot name="footer_right" />
                    </div>
                {/if}
            </div>
        </div>
    {/if}
</div>

<style lang="scss" type="text/scss">
    @use "sass:math";
    @use "../scss/vars" as *;
    @use "../scss/rem" as *;

    $paddingOuter: 20;
    $paddingOuterHalf: math.div($paddingOuter, 2);
    $paddingOuterQuater: math.div($paddingOuter, 4);

    .layout {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: auto;
        scroll-behavior: smooth;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        scrollbar-width: thin;

        .department {
            width: 100%;
            position: sticky;
            top: 0;
            right: 0;
            left: 0;
        }

        .headerwrapper {
            width: 100%;
            z-index: 100;
            position: sticky;
            top: 0;
            right: 0;
            left: 0;

            .header {
                width: 100%;
                box-sizing: border-box;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex: 0;

                &.onlyRight {
                    justify-content: flex-end;
                }

                .left {
                    padding: rem($paddingOuterQuater) rem($paddingOuterHalf) rem($paddingOuterQuater) rem($paddingOuter);
                    margin-top: rem($paddingOuterHalf);
                    color: var(--color-primary-100);
                    font-weight: $font-weight-bold;

                    &.floating {
                        position: absolute;
                        left: 0;
                    }

                    &.flex-column {
                        display: flex;
                        align-items: flex-start;
                        flex-flow: column;
                        gap: rem(4);
                        color: var(--color-disabled);
                        font-size: rem(9);
                        font-weight: $font-weight-regular;
                    }

                    :global {
                        .logo {
                            width: rem(95);
                        }
                    }
                }

                .right {
                    padding: rem($paddingOuterQuater) rem($paddingOuter) rem($paddingOuterQuater) rem($paddingOuterHalf);
                    margin-top: rem($paddingOuterHalf);

                    &.floating {
                        position: absolute;
                        right: 0;
                    }

                    &.flex-column {
                        display: flex;
                        align-items: flex-start;
                        flex-flow: column;
                        gap: rem(8);
                    }
                    &:empty {
                        display: none;
                    }
                }
            }
        }

        .content {
            position: relative;
            margin: 0 auto;
            width: calc(100% - #{rem($paddingOuter * 2)});
            flex: 1;
            flex-flow: column;
            max-width: 100%;
            box-sizing: border-box;
            padding-top: rem($paddingOuter);
            padding-bottom: rem($paddingOuter);

            &.centered {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .footer {
            box-sizing: border-box;
            width: 100%;
            display: flex;
            justify-content: space-between;
            position: sticky;
            left: 0;
            bottom: 0;
            background-color: var(--default-background-color);

            .footer-content {
                box-sizing: border-box;
                max-width: 100%;
                width: calc(100% - #{rem($paddingOuter * 2)});
                margin: 0 auto;
                display: flex;
                justify-content: space-between;

                .left,
                .right {
                    flex-grow: 1;

                    &.floating {
                        position: absolute;
                        bottom: 0;
                    }

                    &.with-padding {
                        padding: rem($paddingOuter);
                    }
                }

                .left {
                    &.floating {
                        left: 0;
                    }
                }

                .right {
                    &.floating {
                        right: rem(20);
                        bottom: rem(30);
                    }
                }
            }
        }

        &.bgColor-modal {
            background-color: var(--default-background-color);

            .header .left,
            .header .right {
                background-color: var(--default-background-color);
            }

            .shadow {
                box-shadow: 1px 1px rem(10) rgba(0, 0, 0, 0.2);
            }
        }

        &.bgColor-page,
        &.bgColor-modal {
            .header .left {
                &.transparent {
                    background: transparent;
                }
            }
        }

        &.size-form {
            .content,
            .footer .footer-content {
                width: min(calc(100% - #{rem($paddingOuter * 2)}), #{rem(500)});
            }
        }

        &.size-orderOverview {
            .content,
            .footer .footer-content {
                width: min(calc(100% - #{rem($paddingOuter * 2)}), #{rem(640)});
            }
        }

        &.size-orderOverviewWide {
            .content,
            .footer .footer-content {
                width: min(calc(100% - #{rem($paddingOuter * 2)}), #{rem(720)});
            }
        }

        &.size-grid {
            .content,
            .footer .footer-content {
                width: min(calc(100% - #{rem($paddingOuter * 2)}), max(70vw, #{rem(500)}));
            }
        }

        &.size-full {
            .content,
            .footer .footer-content {
                width: 100%;
            }
        }

        &.breakpoint-xs,
        &.breakpoint-s {
            .header {
                .left,
                .right {
                    margin-top: 0;
                    padding: rem($paddingOuterHalf);
                }
            }

            .footer {
                .footer-content {
                    .right {
                        &.floating {
                            right: 0;
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }
</style>
