/**
 * Converts a UNIX or ISO timestamp to a desired format
 * @param {number|string} value - The unformatted timestamp in UNIX or ISO format
 * @param {string} [localisation] - default is "de-DE"
 * @param {Intl.DateTimeFormatOptions} [dateOptions]
 * @returns {string} The formatted timestamp
 */
export const formatTimestamp = (
    value: number | string,
    localisation: string = "de-DE",
    dateOptions: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    }
) => {
    return new Date(value).toLocaleDateString(localisation, dateOptions);
};

export const formatDate = (
    date: Date,
    localisation: string = "de-DE",
    dateOptions: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    }
) => {
    return date.toLocaleDateString(localisation, dateOptions);
};
