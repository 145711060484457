<script lang="ts">
    import { onMount, onDestroy } from "svelte";

    let isDebugSidebarVisible: boolean = false;
    let isDebugSidebarOpen: boolean = false;
    let debugSidebarContentEl: HTMLElement;

    const observer = new MutationObserver((_) => {
        const childNodes = debugSidebarContentEl?.childNodes;

        if (childNodes !== undefined) {
            isDebugSidebarVisible = childNodes.length !== 0;

            if (isDebugSidebarVisible === false) {
                isDebugSidebarOpen = false;
            }
        } else {
            isDebugSidebarVisible = false;
            isDebugSidebarOpen = false;
        }
    });

    onMount(() => {
        observer.observe(debugSidebarContentEl, { childList: true });
    });

    onDestroy(() => {
        observer.disconnect();
    });
</script>

<div class="debug-sidebar-component" class:open={isDebugSidebarOpen} class:visible={isDebugSidebarVisible}>
    <button type="button" class="open-close-button" on:click={() => (isDebugSidebarOpen = !isDebugSidebarOpen)}>
        {#if isDebugSidebarOpen}
            <i class="zmdi zmdi-chevron-right" />
        {:else}
            <i class="zmdi zmdi-chevron-left" />
        {/if}
    </button>

    <div id="debug-sidebar" class="content" bind:this={debugSidebarContentEl} />
</div>

<style lang="scss">
    @use "../scss/rem" as *;

    .debug-sidebar-component {
        background-color: var(--default-background-color);
        width: rem(320);
        height: 100%;
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1000;
        transform: translateX(100%);
        transition: transform 0.4s ease-in-out;

        &.visible {
            display: block;
        }

        &.open {
            transform: translateX(0);
        }

        .open-close-button {
            all: unset;
            border-radius: 50%;
            border: 1px solid var(--signal-dark);
            background-color: var(--signal);
            width: rem(24);
            height: rem(24);
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(-150%, -50%);
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                font-size: rem(24);
            }

            &:hover {
                background-color: var(--signal-hover);
                cursor: pointer;
            }
        }

        .content {
            box-sizing: border-box;
            height: 100%;
            padding: rem(12);
            display: flex;
            flex-direction: column;
            gap: rem(12);
            overflow: auto;
        }
    }
</style>
