<script lang="ts">
    import type { EButtonType, EButtonAtomShape, EButtonAtomSize, EButtonAtomColor } from "./ButtonAtomInterface";

    export let active: boolean = false;
    export let disabled: boolean = false;
    export let isInverted: boolean = false;
    export let error: boolean = false;
    export let color: EButtonAtomColor = "primary";
    export let label: string = "";
    export let form: string = "";
    export let type: EButtonType = "button";

    export let size: EButtonAtomSize = "big";

    export let shape: EButtonAtomShape = "default";

    function setAttribute(node: HTMLButtonElement, attribute: { [k: string]: string }) {
        const [attributeName] = Object.keys(attribute);
        const attributeValue = attribute[attributeName!];

        if (attributeName && attributeValue) {
            node.setAttribute(attributeName, attributeValue);
        }
    }
</script>

{#if label.length > 0}
    <div class="wrapper">
        <div class="label">{label}</div>
        <button
            class="{color} {size} {shape}"
            class:active
            class:validation-error={error}
            class:inverted={isInverted}
            on:click
            {disabled}
        >
            <slot>Button</slot>
        </button>
    </div>
{:else}
    <button
        {type}
        class="{color} {size} {shape}"
        class:active
        class:validation-error={error}
        class:inverted={isInverted}
        on:click
        {disabled}
        use:setAttribute={{ form }}
    >
        <slot>Button</slot>
    </button>
{/if}

<style lang="scss" type="text/scss">
    @use "../scss/vars" as *;
    @use "../scss/rem" as *;

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    button,
    .button {
        position: relative;
        margin: 0;
        white-space: nowrap;
        cursor: pointer;
        font-weight: $font-weight-regular;
        display: flex;
        justify-content: center;
        gap: rem(8);
        align-items: center;
        text-decoration: none;
        border: 0;
        padding: rem(10) rem(15);
        font-size: rem(14);
        border-radius: 0;

        &:focus-visible {
            outline: none;
        }

        //sizes
        &.small {
            min-width: rem(70);
        }

        &.big {
            min-width: rem(115);
            min-height: rem(42);
            padding: rem(12) rem(24);
        }

        &.fullwidth {
            width: 100%;
        }

        //forms
        &.circle {
            border-radius: 50%;

            &.small {
                width: rem(30);
                height: rem(30);
                min-width: rem(30); //override min-width from upper small-class

                :global(i) {
                    font-size: rem(18);
                    margin-right: 0;
                }
            }
        }

        &.rounded {
            border-radius: rem($radius);
        }

        &.square {
            padding: 0;

            &.small {
                width: rem(36);
                height: rem(36);
                min-width: unset; //override min-width from upper small-class

                :global(i) {
                    font-size: rem(16);
                    margin: 0;
                }

                :global(svg) {
                    width: rem(11);
                }
            }

            &.big {
                width: rem(42);
                height: rem(42);
                min-width: unset; //override min-width from upper small-class
                padding: rem(6) rem(7);

                :global(i) {
                    font-size: rem(16);
                    margin: 0;
                }
            }
        }

        &:disabled {
            pointer-events: none;
        }

        //colors
        &.primary {
            background-color: var(--color-button-atom-primary-bg);
            border: 1px solid var(--color-button-atom-primary-border);
            color: var(--color-button-atom-primary-label);
            font-weight: $font-weight-bold;
            font-size: rem(14);
            text-transform: uppercase;

            :global {
                svg path {
                    fill: var(--color-secondary-100);
                }
            }

            :global(i) {
                font-size: rem(16);
                margin: 0;
            }

            &.active {
                background-color: var(--color-button-atom-primary-bg--active);
                box-shadow: 0 0 0 1px var(--signal-dark);

                &:hover {
                    background-color: var(--color-button-atom-primary-bg--active--hover);
                }
            }

            &:hover {
                background-color: var(--color-button-atom-primary-bg--hover);
                box-shadow: 0 0 0 1px var(--signal-dark);
            }

            &:disabled {
                background-color: var(--color-button-atom-primary-bg--disabled);
                border-color: var(--color-button-atom-primary-border--disabled);
                color: var(--color-button-atom-primary-label--disabled);
                cursor: default;
            }
        }

        &.secondary {
            background-color: var(--color-button-atom-secondary-bg);
            border: 1px solid var(--color-button-atom-secondary-border);
            color: var(--color-button-atom-secondary-label);
            font-weight: $font-weight-bold;
            font-size: rem(14);
            text-transform: uppercase;

            :global {
                svg path {
                    fill: var(--color-button-atom-secondary-label);
                }
            }

            :global(i) {
                font-size: rem(16);
                margin: 0;
            }

            &.active {
                color: var(--color-button-atom-secondary-label--active);
                background-color: var(--color-button-atom-secondary-bg--hover);

                &:hover {
                    color: var(--color-button-atom-secondary-label--active--hover);
                    background-color: var(--color-button-atom-secondary-bg--hover);
                }
            }

            &:hover {
                color: var(--color-button-atom-secondary-label--hover);
                background-color: var(--color-button-atom-secondary-bg--hover);
                box-shadow: 0 0 0 1px var(--signal-dark);
            }

            &:disabled {
                border-color: var(--color-button-atom-secondary-border--disabled);
                color: var(--color-button-atom-secondary-label--disabled);
                cursor: default;
            }
        }

        &.secondary-red {
            background-color: var(--default-background-color);
            border: 1px solid var(--color-error-100);
            color: var(--color-error-100);
            font-weight: $font-weight-bold;
            font-size: rem(14);
            text-transform: uppercase;

            :global {
                svg path {
                    fill: var(--color-error-100);
                }
            }

            &.active {
                color: var(--color-error-100);

                &:hover {
                    color: var(--color-error-100);
                }
            }

            &:hover {
                color: var(--color-error-100);
            }

            &:disabled {
                border-color: var(--color-secondary-32);
                color: var(--color-secondary-32);
                cursor: default;

                :global {
                    svg path {
                        fill: var(--color-secondary-32);
                    }
                }
            }
        }

        &.red {
            background-color: var(--color-error-100);
            color: var(--default-background-color);

            &[class*="active"] {
                background-color: var(--color-red-12);
                color: var(--default-background-color);

                &:hover {
                    background-color: var(--color-red-76);
                    color: var(--default-background-color);
                }
            }

            &:hover {
                background-color: var(--color-red-76);
                color: var(--default-background-color);
            }

            &:disabled {
                background-color: var(--color-red-32);
                color: var(--default-background-color);
            }
        }

        &.inverted {
            background-color: var(--default-background-color);
            color: var(--color-primary-100);

            &[class*="active"] {
                background-color: var(--color-primary-4);
                color: var(--color-primary-100);

                &:hover {
                    background-color: var(--color-primary-4);
                    color: var(--color-primary-100);
                }
            }

            &:hover {
                background-color: var(--color-primary-4);
                color: var(--color-primary-100);
            }

            &:disabled {
                background-color: var(--color-primary-4);
                color: var(--color-primary-4);
            }
        }
        &.transparent {
            background-color: transparent;
            color: var(--color-secondary-88);

            &[class*="active"] {
                background-color: transparent;
                color: var(--color-secondary-88);

                &:hover {
                    background-color: var(--color-primary-4);
                    color: var(--color-primary-100);
                }
            }

            &:hover {
                background-color: var(--color-primary-4);
            }

            &:disabled {
                background-color: var(--color-primary-4);
                color: var(--color-primary-4);
            }
        }

        &.unpublished {
            background-color: var(--color-button-atom-unpublished-bg);
            border: 1px solid var(--color-button-atom-unpublished-border);
            color: var(--color-button-atom-unpublished-label);
            font-weight: $font-weight-bold;
            font-size: rem(14);
            text-transform: uppercase;

            :global(i) {
                font-size: rem(16);
                margin: 0;
            }

            :global {
                svg {
                    width: rem(16);
                    height: rem(16);
                }
            }

            &.active {
                color: var(--color-button-atom-unpublished-label--active);
                background-color: var(--color-button-atom-unpublished-bg--hover);

                &:hover {
                    color: var(--color-button-atom-unpublished-label--active--hover);
                    background-color: var(--color-button-atom-unpublished-bg--hover);
                }
            }

            &:hover {
                color: var(--color-button-atom-unpublished-label--hover);
                background-color: var(--color-button-atom-unpublished-bg--hover);
            }

            &:disabled {
                background-color: var(--color-button-atom-unpublished-bg--disabled);
                border-color: var(--color-button-atom-unpublished-border--disabled);
                color: var(--color-button-atom-unpublished-label--disabled);
                cursor: default;
            }
        }
    }
</style>
