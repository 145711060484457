import type { Action } from "svelte/action";

function findTarget(element: HTMLElement): HTMLElement | null {
    // console.log("ELEMENT", element);

    const isTarget = element.dataset["clickoutsideTarget"] === "true";

    if (isTarget === true) {
        return element;
    }

    if (element.parentElement !== null) {
        return findTarget(element.parentElement);
    }

    return null;
}

export const clickOutside: Action<
    HTMLElement,
    { useCapture?: boolean } | undefined,
    { "on:clickOutside": (e: Event) => void }
> = (element, options) => {
    // Search the dom hierarchy upwards to find a potential target.
    // If nothing is found, use the element itself.
    const outsideElement = findTarget(element) ?? element;

    const handler = (event: MouseEvent) => {
        const target = event.target;

        if (outsideElement !== target && !outsideElement.contains(target as HTMLElement) && !event.defaultPrevented) {
            element.dispatchEvent(new CustomEvent("clickOutside", element as CustomEventInit<HTMLElement>));
        }
    };

    const requestId = requestAnimationFrame(() => {
        window.document.addEventListener("click", handler, options?.useCapture ?? true);
    });

    return {
        destroy: () => {
            cancelAnimationFrame(requestId);
            window.document.removeEventListener("click", handler, options?.useCapture ?? true);
        },
    };
};

export const pointerDownOutside: Action<
    HTMLElement,
    { useCapture?: boolean } | undefined,
    { "on:pointerDownOutside": (e: Event) => void }
> = (element, options): { destroy: () => void } => {
    // Search the dom hierarchy upwards to find a potential target.
    // If nothing is found, use the element itself.
    const outsideElement = findTarget(element) ?? element;

    const handler = (event: PointerEvent) => {
        const target = event.target;

        if (outsideElement !== target && !outsideElement.contains(target as HTMLElement) && !event.defaultPrevented) {
            element.dispatchEvent(new CustomEvent("pointerDownOutside", element as CustomEventInit<HTMLElement>));
        }
    };

    const requestId = requestAnimationFrame(() => {
        window.document.addEventListener("pointerdown", handler, options?.useCapture ?? true);
    });

    return {
        destroy: () => {
            cancelAnimationFrame(requestId);
            window.document.removeEventListener("pointerdown", handler, options?.useCapture ?? true);
        },
    };
};
