<script lang="ts" context="module">
    export type SavePlanningModalProps = {
        existingPlanning?: {
            label: string;
            hash_id: string;
            timestamp: Date;
        };
    };
</script>

<script lang="ts">
    import Modal from "../Modal.svelte";
    import type { SelectableAtomItem } from "../../../atoms/SelectableAtomInterface";
    import ButtonAtom from "../../../atoms/ButtonAtom.svelte";
    import InputAtom from "../../../atoms/InputAtom.svelte";
    import SelectableAtom from "../../../atoms/SelectableAtom.svelte";
    import { loc } from "../../../store/loc.store";
    import { formatDate } from "../../../utils/timestamp";

    export let modalProps: SavePlanningModalProps;

    const { existingPlanning } = modalProps;

    let localLabel = existingPlanning !== undefined ? existingPlanning.label : "";

    const isOverWritable = existingPlanning !== undefined;

    let planningSaveOptions: SelectableAtomItem[] = [
        {
            id: "1",
            value: "overwrite",
            label: $loc.map("locKey_modal_save_planning_overwrite"),
            selected: isOverWritable,
            disabled: !isOverWritable,
        },
        {
            id: "2",
            value: "new",
            label: $loc.map("locKey_modal_save_planning_save_as"),
            selected: !isOverWritable,
        },
    ];

    function selectOption(id: string) {
        planningSaveOptions.forEach((saveOption) => {
            if (saveOption.selected) {
                saveOption.selected = false;
            }

            if (saveOption.id === id) {
                saveOption.selected = true;
            }
        });
        planningSaveOptions = planningSaveOptions;
    }

    $: selectedSaveOption = planningSaveOptions.find((option) => option.selected === true).value ?? "new";
</script>

<Modal backdrop={true} let:dispatchModalAction on:modalAction>
    <svelte:fragment slot="header">
        <h2>{$loc.map("locKey_modal_save_planning")}</h2>
    </svelte:fragment>

    <form
        on:submit|preventDefault={() =>
            dispatchModalAction({
                action: "save",
                payload: { name: localLabel, saveOption: selectedSaveOption },
            })}
    >
        <InputAtom id="name-input" placeholder={$loc.map("locKey_modal_save_planning_name")} bind:value={localLabel}>
            <i slot="prefix" class="zmdi zmdi-edit" />
        </InputAtom>

        <div class="planning-timestamp" class:new={selectedSaveOption === "new"}>
            {#if selectedSaveOption === "overwrite"}
                {formatDate(existingPlanning.timestamp)}
            {:else}
                {formatDate(new Date())}
            {/if}
        </div>

        <SelectableAtom items={planningSaveOptions} let:item on:click={(e) => selectOption(e.detail.id)}>
            {item.label}
        </SelectableAtom>
    </form>

    <svelte:fragment slot="footer" let:dispatchModalAction let:close>
        <ButtonAtom color="secondary" size="small" on:click={close}>
            {$loc.map("locKey_cancel")}
        </ButtonAtom>

        <ButtonAtom
            color="primary"
            size="small"
            on:click={() =>
                dispatchModalAction({
                    action: "save",
                    payload: { name: localLabel, saveOption: selectedSaveOption },
                })}
        >
            {$loc.map("locKey_save")}
            <i class="zmdi zmdi-long-arrow-right" />
        </ButtonAtom>
    </svelte:fragment>
</Modal>

<style lang="scss" type="text/scss">
    @use "../../../scss/vars" as *;
    @use "../../../scss/rem" as *;

    form {
        display: flex;
        flex-direction: column;
        gap: rem(24);

        i {
            color: var(--color-text);
        }

        .planning-timestamp {
            color: var(--color-text);
            font-size: rem(11);
            font-weight: 400;

            &.new {
                color: var(--signal-dark);
            }
        }
    }
</style>
