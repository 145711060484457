import { writable } from "svelte/store";
import { login as apiLogin, isLoggedIn, userDetails } from "../services/fetch.api";

export type User = {
    user_id: string | undefined;
    isLoggedIn: boolean;
    department: "sales" | "calculation";
    email?: string;
    firstname?: string;
    lastname?: string;
    configurationInfo?: {
        id: string;
        label: string;
    };
    source?: string;
};

export const userStore = writable<User>(
    {
        user_id: undefined,
        isLoggedIn: false,
        department: "sales",
        email: undefined,
        firstname: undefined,
        lastname: undefined,
        configurationInfo: undefined,
        source: undefined,
    },
    (set) => {
        isLoggedIn()
            .then(async (state) => {
                if (state) {
                    const { firstname, lastname, email, department, source, user_id } = await userDetails();

                    set({
                        user_id,
                        isLoggedIn: state,
                        email,
                        firstname,
                        lastname,
                        department,
                        source,
                    });
                }
            })
            .catch((error: Error) => console.error(error));
    }
);

export const login = async (email: string, password: string, type: "ad" | "local") => {
    try {
        const successful = await apiLogin(email, password, type);
        const { firstname, lastname, department, source, user_id } = await userDetails();

        if (successful) {
            userStore.set({
                user_id,
                isLoggedIn: successful,
                firstname,
                lastname,
                email,
                department,
                configurationInfo: undefined,
                source,
            });
        }

        return successful;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const logout = () => {
    userStore.set({
        user_id: undefined,
        isLoggedIn: false,
        department: "sales",
        email: undefined,
        firstname: undefined,
        lastname: undefined,
        configurationInfo: undefined,
        source: undefined,
    });

    localStorage.removeItem("accessToken");
    location.reload();
};

export const setConfigurationInfo = (configurationInfo: User["configurationInfo"]) => {
    userStore.update((user) => ({
        ...user,
        configurationInfo,
    }));
};

export const removeConfigurationInfo = () => {
    userStore.update((user) => ({
        ...user,
        configurationInfo: undefined,
    }));
};
