import { Platform } from "@redplant3d/redtyped/lib/core/Platform";
import type { Action } from "svelte/action";
import Tooltip from "../components/Tooltip.svelte";

export const tooltip: Action<HTMLElement> = (element) => {
    let title;
    let tooltipComponent;

    function mouseOver(event) {
        //get tooltip text
        title = element.getAttribute("data-tooltip");

        //no text given? do nothing!
        if (!title) {
            return;
        }

        //no tooltips for mobile devices!
        if (Platform.get().isMobile && Platform.get().isTouchDevice) {
            return;
        }

        //tooltipComponent exists already on mouseover? destroy it!
        if (tooltipComponent) {
            tooltipComponent.$destroy();
        }

        //create new tooltip
        tooltipComponent = new Tooltip({
            props: {
                title: title,
                x: event.pageX,
                y: event.pageY,
            },
            target: document.body,
        });
    }

    //update tooltip position
    function mouseMove(event) {
        if (tooltipComponent) {
            tooltipComponent.$set({
                x: event.pageX,
                y: event.pageY,
            });
        }
    }

    //destroy tooltip
    function mouseLeave() {
        if (tooltipComponent) {
            tooltipComponent.$destroy();
        }
    }

    element.addEventListener("mouseover", mouseOver);
    element.addEventListener("mouseleave", mouseLeave);
    element.addEventListener("mousemove", mouseMove);

    return {
        destroy() {
            if (tooltipComponent) {
                element.removeEventListener("mouseover", mouseOver);
                element.removeEventListener("mouseleave", mouseLeave);
                element.removeEventListener("mousemove", mouseMove);
            }
        },
    };
};
