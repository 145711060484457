<script lang="ts">
    import type { SelectableAtomItem } from "./SelectableAtomInterface";
    import { createEventDispatcher } from "svelte";
    import { tooltip } from "../actions/tooltip";

    const dispatch = createEventDispatcher<{
        click: {
            id: string;
            value: any;
        };
    }>();

    function itemClick(item: SelectableAtomItem) {
        dispatch("click", { id: item.id, value: item.value });
    }

    export let autoSize: boolean = false;
    export let rows: 0 | 1 | 2 | 3 = 0;
    export let inverted: boolean = false;
    export let items: SelectableAtomItem[] = [];
</script>

<div class="selectable-atom rows{rows}" class:inverted>
    {#each items as item}
        <button
            type="button"
            data-tooltip={item.tooltip}
            use:tooltip
            class="selectable-item {item.thumbnail ? 'with-thumbnail' : ''}"
            class:selected={item.selected}
            class:disabled={item.disabled}
            class:autoSize
            on:click={() => itemClick(item)}
        >
            <slot {item} />
        </button>
    {/each}
</div>

<style lang="scss" type="text/scss">
    @use "../scss/vars" as *;
    @use "../scss/rem" as *;

    $gap: rem(2);

    .selectable-atom {
        padding: $gap 0 0 $gap;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: center;
        background-color: var(--color-selectable-bg);
        border-radius: rem(24);

        &:has(.selectable-item.with-thumbnail) {
            border-radius: rem(12);
        }

        .selectable-item {
            all: unset;
            position: relative;
            box-sizing: border-box;
            padding: rem(8);
            margin: 0 $gap $gap 0;
            text-align: center;
            color: var(--color-selectable-item-label);
            font-size: rem(10);
            flex: 1 1 0;
            cursor: pointer;
            background-color: transparent;
            border-radius: rem(24);
            border: 1px solid transparent;
            font-size: rem(10);
            line-height: rem(12);

            &.autoSize {
                flex: 1 1 auto;
            }

            &.with-thumbnail {
                border-radius: rem(12);
                color: var(--color-selectable-item-thumbnail-label);

                :global {
                    img {
                        height: rem(30);
                    }
                }

                &.selected {
                    border-color: var(--color-selectable-item-border--selected);
                    background-color: var(--color-selectable-item-thumbnail-bg--selected);
                    color: var(--color-selectable-item-thumbnail-label--selected);

                    &:hover {
                        border-color: var(--color-selectable-item-border--selected--hover);
                        background-color: var(--color-selectable-item-thumbnail-bg--selected--hover);
                    }
                }
            }

            &.selected {
                border-color: var(--color-selectable-item-border--selected);
                background-color: var(--color-selectable-item-bg--selected);
                font-weight: $font-weight-bold;
                color: var(--color-selectable-item-label--selected);

                &:hover {
                    background-color: var(--color-selectable-item-bg--selected--hover);
                    color: var(--color-selectable-item-label--selected--hover);
                }
            }

            &.disabled {
                background-color: transparent;
                color: var(--color-secondary-32);
                pointer-events: none;

                &.selected {
                    border-color: var(--color-secondary-32);
                    font-weight: initial;
                }
            }

            &:hover {
                background-color: var(--color-selectable-item-bg--hover);
                border-color: var(--color-selectable-item-border--hover);
                color: var(--color-selectable-item-label--hover);
            }
        }

        &.rows1 {
            .selectable-item {
                flex: 0 100%;
            }
        }

        &.rows2 {
            .selectable-item {
                flex: 0 calc(50% - #{$gap});
            }
        }

        &.rows3 {
            .selectable-item {
                flex: 0 calc(33.33% - #{$gap});
            }
        }

        &.inverted {
            .selectable-item {
                border: 1px solid var(--color-primary-4);

                &:hover,
                &.selected {
                    color: var(--color-primary-60);
                    background-color: var(--default-background-color);
                    border: 1px solid var(--color-primary-60);
                }
            }
        }
    }
</style>
