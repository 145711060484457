<script lang="ts">
    import { loc } from "../store/loc.store";
    import type { User } from "../store/user.store";

    export let department: User["department"];
    export let sapSystem: User["source"];
    export let configurationInfo: User["configurationInfo"] = undefined;
</script>

<div class="banner department-{department}">
    <div class="department">
        {$loc.map("locKey_department_" + department)}
        {#if department === "calculation" && sapSystem !== undefined}
            <div class="sap-system">
                {sapSystem}
            </div>
        {/if}
    </div>
    {#if configurationInfo !== undefined}
        <div class="configuration">
            <div class="label">{configurationInfo.label}</div>
        </div>
    {/if}
</div>

<style lang="scss" type="text/scss">
    @use "../scss/vars.scss" as *;
    @use "../scss/rem.scss" as *;
    .banner {
        display: flex;
        padding: rem(9) rem(18);
        align-items: center;
        justify-content: space-between;
        font-family: "Noto Sans", "Ubuntu", sans-serif;

        &.department-sales {
            background-color: var(--signal);
            color: var(--signal-dark);
        }

        &.department-calculation {
            background-color: var(--color-calculation-24);
            color: var(--color-calculation-100);
        }

        .department {
            display: flex;
            align-items: center;
            gap: rem(16);
            font-size: rem(14);
            font-weight: 700;

            .sap-system {
                font-size: rem(11);
                line-height: 1.4;
                color: var(--color-primary-100);
            }
        }

        .configuration {
            display: flex;
            align-items: center;
            gap: rem(16);

            .label {
                font-size: rem(14);
                font-weight: 700;
                color: var(--color-primary-100);
            }
        }
    }
</style>
